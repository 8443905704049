Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
}

/* what the newly created button does */
Trix.config.textAttributes.red = {
    style: { color: "red" },
    parser: function(element) {
        return element.style.color === "red"
    },
    inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var buttonRED = '<button type="button" data-trix-attribute="red">RED</button>'
    var buttonH2 = '<button type="button" data-trix-attribute="heading2">H2</button>'

    const toolbar = event.target.toolbarElement.
    querySelector(".trix-button-group")

    toolbar.insertAdjacentHTML("beforeend", buttonRED)
    toolbar.insertAdjacentHTML("beforeend", buttonH2)

/*     const toolbar = event.target.toolbarElement
    .querySelector(".trix-button--icon-increase-nesting-level")

    toolbar.insertAdjacentHTML("afterend", buttonRED)
    toolbar.insertAdjacentHTML("afterend", buttonH2) */
}, { once: true })